// Types
import { RootState } from 'types/RootState.types';

// Config
import { isFeatureActive } from '@redux/reducers/config/configReducer';
import featureConstants from '@constants/featureConstants';

export const getConfigFeaturesSelector = (state: RootState) => state.config?.features;
export const getLoqateApiKeySelector = (state: RootState) => state.config?.frontendKeys?.loqateApiKey;
export const getGoogleMapsApiKeySelector = (state: RootState) => state.config?.frontendKeys?.googleMapsApiKey;
export const getSessionWarningAfterTime = (state: RootState) => state.config?.sessionWarningAfterTime;
export const getGooglePayConfiguration = (state: RootState) => state.config?.googlePayConfiguration ?? {};
export const getPaymentMessageFeatureSelector = (state: RootState) =>
  state?.config?.features?.find((f) => f?.id === featureConstants.PAYMENT_PAGE_BANNER_ENABLED);
export const getClickAndCollectCutOffWindowInMinutesSelector = (state: RootState) =>
  state.config?.clickAndCollectCutOffWindowInMinutes;
export const getContinueButtonSecureIconFeatureActive = (state: RootState) =>
  isFeatureActive(state, featureConstants.CONTINUE_TO_PAYMENT_SECURE_ICON);
